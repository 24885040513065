function parseComment(comment, personsMultiplier) {
    let r = comment;
    if (typeof comment === 'object') {
        r = comment.text;
        for (let i = 0; r.includes('{}'); i++) {
            r = r.replace('{}', comment.params[i] * personsMultiplier);
        }
    }
    return r;
}

const flatMap = (f, xs) => xs.reduce((acc, x) => acc.concat(f(x)), []);

export { parseComment, flatMap };
