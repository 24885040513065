import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Checkbox from '@material-ui/core/Checkbox';

import { toReadableFraction } from 'readable-fractions';
import { parseComment } from '../utils';

const styles = theme => ({
    item: {
        paddingTop: 0,
        paddingBottom: 0,
    },
});

const renderFraction = amount => {
    let { numerator, denominator } = toReadableFraction(amount);
    let n = 0;
    while (numerator >= denominator) {
        numerator -= denominator;
        n++;
    }
    return (
        <span>
            {n > 0 && `${n} `}
            {numerator > 0 && (
                <span>
                    <sup>{numerator}</sup>
                    {'/'}
                    <sub>{denominator}</sub>{' '}
                </span>
            )}
        </span>
    );
};

const Ingredient = props => {
    let { classes, ingredient, personsMultiplier } = props;
    let comment = parseComment(ingredient.comment, personsMultiplier);
    let { amount, unit, name } = ingredient;
    let primaryText = (
        <span>
            {amount && renderFraction(personsMultiplier * amount)}
            {unit && `${unit} `}
            {name && name}
        </span>
    );
    return (
        <ListItem className={classes.item}>
            <Checkbox />
            <ListItemText primary={primaryText} secondary={comment} />
        </ListItem>
    );
};

Ingredient.propTypes = {
    ingredient: PropTypes.object.isRequired,
    personsMultiplier: PropTypes.number.isRequired,
};

export default withStyles(styles)(Ingredient);
