import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import { parseComment } from '../utils';

const styles = theme => ({
    item: {},
});

const Instruction = props => {
    let { classes, instruction, number, personsMultiplier } = props;
    instruction = parseComment(instruction, personsMultiplier);
    return (
        <ListItem className={classes.item}>
            <Typography variant="subtitle1" style={{ paddingRight: '10px' }}>{number}.</Typography>
            <ListItemText primary={instruction} />
        </ListItem>
    );
};

Instruction.propTypes = {
    instruction: PropTypes.PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    number: PropTypes.number.isRequired,
    personsMultiplier: PropTypes.number.isRequired,
};

export default withStyles(styles)(Instruction);
