import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Ingredient from './Ingredient';
import Instruction from './Instruction';

const styles = theme => ({
    imageContainer: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        padding: '8px',
    },
    image: {
        flexShrink: '0',
        maxWidth: '100%',
        maxHeight: '100%',
    },
});

const Recipe = props => {
    const { classes, name, image, ingredients, instructions, recipePersons, targetPersons, style } = props;
    let personsMultiplier = targetPersons > 0 ? targetPersons / recipePersons : 1;

    return (
        <div style={style}>
            <Grid container spacing={24}>
                <Grid item xs={12} sm={6} md={4}>
                    <div className={classes.imageContainer}>
                        <img src={'/img/' + image} alt={name} className={classes.image}/>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="h6">
                        Ingredients
                    </Typography>
                    <List>
                        {ingredients &&
                            ingredients.map((ingredient, i) => (
                                <Ingredient key={i} ingredient={ingredient} personsMultiplier={personsMultiplier} />
                            ))}
                    </List>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                    <Typography variant="h6">
                        Instructions
                    </Typography>
                    <List>
                        {instructions &&
                            instructions.map((instruction, i) => (
                                <Instruction
                                    key={i}
                                    number={i + 1}
                                    instruction={instruction}
                                    personsMultiplier={personsMultiplier}
                                />
                            ))}
                    </List>
                </Grid>
            </Grid>
        </div>
    );
};

Recipe.propTypes = {
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    ingredients: PropTypes.array.isRequired,
    instructions: PropTypes.array,
    recipePersons: PropTypes.number.isRequired,
    targetPersons: PropTypes.number.isRequired,
    style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default withStyles(styles)(Recipe);
