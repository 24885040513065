import React, { Component } from 'react';
import Recipe from '../components/Recipe';
import PropTypes from 'prop-types';

import { Redirect } from 'react-router-dom';

import RecipeAppBar from '../components/RecipeAppBar';

class RecipePage extends Component {
    render() {
        let recipe = this.props.recipes[this.props.match.params.id];
        return recipe ? (
            <div>
                <RecipeAppBar
                    numberPeople={this.props.numberPeople}
                    onChangeNumberPeople={this.props.onChangeNumberPeople}
                    recipeName={recipe.name}
                    recipeNumberPeople={recipe.persons}
                />
                <Recipe
                    name={recipe.name}
                    image={recipe.image}
                    ingredients={recipe.ingredients}
                    instructions={recipe.instructions}
                    recipePersons={recipe.persons}
                    targetPersons={this.props.numberPeople}
                    style={{ margin: 5 }}
                />
            </div>
        ) : (
            <Redirect to={'/'} />
        );
    }
}

RecipePage.propTypes = {
    numberPeople: PropTypes.number.isRequired,
    onChangeNumberPeople: PropTypes.func.isRequired,
    recipes: PropTypes.object.isRequired,
};

export default RecipePage;
