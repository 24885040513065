import React, { Component } from 'react';

import { Switch, Route } from 'react-router-dom';

import NoSleep from "nosleep.js";

import RecipePage from './pages/RecipePage';
import RecipeGridPage from './pages/RecipeGridPage';

import { flatMap } from './utils';

//import RECIPES_JSON from 'multi-json-loader?cwd=src/recipes!./empty.json';
//import RECIPES_YAML from './recipes/*.yaml';


let RECIPES = [
    //'b52.yaml',
    //'bazookajoe.yaml',
    'banana_bread.yaml',
    'banana_cinnamon_pancake.yaml',
    'baozi_pork.yaml',
    'busiate.yaml',
    'cheesecake.yaml',
    'chicken_wrap.yaml',
    'chili_con_carne.yaml',
    'chocolate_chunk_cookies.yaml',
    'coffee_smoothie.yaml',
    'emperor_salad.yaml',
    'eggnogg.yaml',
    'eggtarts.yaml',
    //'espresso_martini.yaml',
    'fajitas.yaml',
    'fondue_alkoholfrei.yaml',
    'germknoedel.yaml',
    'guobaorou.yaml',
    'kaes_knoepfle.json',
    'kaiserschmarrn.yaml',
    'koreanblackbean.yaml',
    'koreanfriedchicken.yaml',
    'koreanvegipancake.yaml',
    'lammschulter.yaml',
    'lasagna.yaml',
    'laziji.yaml',
    'mahua.yaml',
    'mantou.yaml',
    'mojito.yaml',
    //'moscow_mule.yaml',
    'palatschinken.yaml',
    'pilz_spaghetti.yaml',
    'pizza_dough.yaml',
    'pretty_raspberry_cake.json',
    'raspberry_tiramisu.yaml',
    'revuelto_setas_langostinos.yaml',
    'shan_waffels_2.json',
    'penne_bolognese.yaml',
    'sweet_potato_fries.json',
    'semmelknoedel.yaml',
    'spaghetti_aglio_olio_e_gamberi.yaml',
    'spicypot.yaml',
    'tiramisu.yaml',
    'tiramisu_eggfree.yaml',
    'tortellini_tomato.yaml',
    'vanille_kipferl.yaml',
    'vegispaghetti_qielaxi.yaml',
    'vietnamese_egg_coffee.yaml',
    'wild_gschnetzlets.yaml',
];


class App extends Component {
    state = {
        numberPeople: 0,
        selectedTags: [],
    };

    constructor() {
        super();

        let noSleep = new NoSleep();
        document.addEventListener('click', function enableNoSleep() {
              document.removeEventListener('click', enableNoSleep, false);
              noSleep.enable();
        }, false);

        let recipes = RECIPES.map(i => require(`./recipes/${i}`));
        let id_from_name = (item) => {
            return item.name
                .toLowerCase()
                .replace(/ /g,"_")
                .replace('ä', 'ae')
                .replace('ö', 'oe')
                .replace('ü', 'ue')
                .replace(/[^a-zA-Z0-9\-_.]+/g, "");
        };
        recipes = recipes.reduce((obj, item) => {
            let id = item.id || id_from_name(item);
            obj[id] = item;
            return obj;
        }, {});

        let tags = {};
        flatMap(r => r.tags, Object.values(recipes))
            .filter(t => t)
            .forEach(x => (tags[x] = (tags[x] || 0) + 1));

        this.state = { recipes, tags, ...this.state };
    }

    onSelectTags = selectedTags => {
        this.setState({ selectedTags });
    };

    onChangeNumberPeople = numberPeople => {
        this.setState({ numberPeople });
    };

    renderRecipe = props => {
        let { recipes } = this.state;
        return (
            <RecipePage
                numberPeople={this.state.numberPeople}
                onChangeNumberPeople={this.onChangeNumberPeople}
                recipes={recipes}
                {...props}
            />
        );
    };

    renderRecipeGrid = props => {
        let { recipes, tags, selectedTags } = this.state;
        return (
            <RecipeGridPage
                numberPeople={this.state.numberPeople}
                onChangeNumberPeople={this.onChangeNumberPeople}
                recipes={recipes}
                tags={tags}
                selectedTags={selectedTags}
                onSelectTags={this.onSelectTags}
                {...props}
            />
        );
    };

    render() {
        return (
            <Switch>
                <Route exact path="/" render={this.renderRecipeGrid} />
                <Route path="/recipes/:id" render={this.renderRecipe} />
            </Switch>
        );
    }
}

export default App;
