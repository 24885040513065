import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import TagSelect from './TagSelect';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    flex: {
        flex: 1,
        alignItems: 'center',
    },
    title: {
        display: 'inline',
        paddingRight: 8,
        verticalAlign: 'middle',
    },
    selector: {
        display: 'inline-block',
        verticalAlign: 'middle',
    },
    persons: {
        color: theme.palette.primary.contrastText,
    },
    arrowButton: {
        marginLeft: -12,
        marginRight: 20,
    },
});

class RecipeAppBar extends Component {
    renderNumberPersons = numberPersons => {
        return `for ${numberPersons} Person${numberPersons > 1 ? 's' : ''}`;
    };

    handlePersonClick = e => {
        e.preventDefault();
        this.props.onChangeNumberPeople(parseInt(e.target.value));
    };

    handleChangeTags = tags => {
        this.props.onSelectTags(tags);
    };

    renderSelectValue = value => {
        let numberPersons = value > 0 ? value : this.props.recipeNumberPeople;
        return (
            <Typography variant="h6" className={this.props.classes.persons}>
                {this.renderNumberPersons(numberPersons)}
            </Typography>
        );
    };

    render() {
        const { recipeName, numberPeople, classes, tags, selectedTags } = this.props;
        return (
            <AppBar key={1} position="sticky">
                <Toolbar>
                    {recipeName && (
                        <Link to={'/'} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                            <IconButton
                                className={classes.arrowButton}
                                color="inherit"
                                aria-label="Menu"
                            >
                                <ArrowBack />
                            </IconButton>
                        </Link>
                    )}
                    <div className={classes.flex}>
                        <Typography variant="h6" color="inherit" className={classes.title}>
                            {recipeName ? recipeName : "Shan's Best Recipes"}
                        </Typography>
                        {recipeName && (
                            <Select
                                value={numberPeople}
                                onChange={this.handlePersonClick}
                                renderValue={this.renderSelectValue}
                                className={classes.selector}
                            >
                                <MenuItem value={0}>
                                    <em>Original</em>
                                </MenuItem>
                                {Array.from({ length: 10 }, (x, i) => i + 1).map(j => (
                                    <MenuItem key={j} value={j}>
                                        {this.renderNumberPersons(j)}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    </div>
                    {tags && (
                        <TagSelect tags={tags} selectedTags={selectedTags} handleChangeTags={this.handleChangeTags} />
                    )}
                </Toolbar>
            </AppBar>
        );
    }
}

RecipeAppBar.propTypes = {
    recipeName: PropTypes.string,
    recipeNumberPeople: PropTypes.number,
    numberPeople: PropTypes.number.isRequired,
    onChangeNumberPeople: PropTypes.func.isRequired,
    tags: PropTypes.object,
    onSelectTags: PropTypes.func,
};

export default withStyles(styles)(RecipeAppBar);
