import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Select from 'react-select';


const styles = theme => ({});

const selectStyle = theme => ({
    control: (base, state) => ({
        ...base,
        color: theme.palette.primary.contrastText,
        background: theme.palette.primary.main,
        minWidth: 125,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: theme.palette.primary.main,
            //color: theme.palette.text.main,
        };
    },
    input: styles => ({
        ...styles,
    }),
    placeholder: styles => ({
        ...styles,
        color: theme.palette.text.main,
    }),
    clearIndicator: styles => ({
        ...styles,
        color: theme.palette.primary.contrastText,
    }),
    dropdownIndicator: styles => ({
        ...styles,
        color: theme.palette.primary.contrastText,
    }),
    multiValue: (styles, { data }) => ({
        ...styles,
        //color: theme.palette.text.main,
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
    }),
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ':hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
    }),
});

class TagSelect extends Component {
    state = {
        selectedTags: [],
    };

    constructor(props) {
        super(props);

        let { tags, selectedTags } = props;

        let selectableTags = Object.keys(tags).map(t => ({ value: t, label: `${t} (${tags[t]})` }));
        selectableTags.sort((a, b) => (a.label < b.label ? -1 : 1));

        this.state = {
            selectableTags,
            selectedTags,
            ...this.state,
        };
    }

    handleChange = selectedTags => {
        let propTags = selectedTags.map(x => x.value);
        this.props.handleChangeTags(propTags);
    };

    render() {
        const { classes, theme, selectedTags, tags } = this.props;
        const { selectableTags } = this.state;
        let selectedTagObjects = selectedTags.map(t => ({ value: t, label: `${t} (${tags[t]})` }));
        return (
            <Select
                isMulti
                name="tags"
                options={selectableTags}
                value={selectedTagObjects}
                onChange={this.handleChange}
                placeholder="Tags"
                className={classes.input}
                styles={selectStyle(theme)}
            />
        );
    }
}

TagSelect.propTypes = {
    tags: PropTypes.object.isRequired,
    selectedTags: PropTypes.array.isRequired,
    handleChangeTags: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(TagSelect);
