import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { Link } from 'react-router-dom';
import RecipeAppBar from '../components/RecipeAppBar';
import RecipeTile from '../components/RecipeTile';

const styles = theme => ({
    root: {
        // display: 'flex',
        // flexWrap: 'wrap',
        // justifyContent: 'space-around',
        // // overflow: 'hidden',
        // backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
        padding: 8,
    },
    gridList: {
        display: 'flex',
        // width: 500,
        // height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',

        justifyContent: 'left',
        alignItems: 'left',
        overflow: 'hidden',
        '& img': {
            flexShrink: 1,
        },
    },
    titleBar: {
        background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
        color: 'white',
    },
    paper: {
        overflow: 'hidden',
        display: 'block',
        height: '80%',
    },
    image: {
        position: 'relative',
        width: '100%',
        resizeMode: 'contain',
        height: '100%',
        display: 'inline-block',
        backgroundSize: 'cover',
        // transform: 'translate(-50%)',
        // left: '50%',
    },
    bottom: {
        position: 'absolute',
        display: 'flex',
        bottom: 0,
        left: 0,
        right: 0,
        height: 68,
        background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
});

const getRecipesList = recipes => {
    let recipesList = [];
    for (let k in recipes) {
        let v = recipes[k];
        v['id'] = k;
        recipesList.push(v);
    }
    recipesList.sort((a, b) => a.name.localeCompare(b.name));
    return recipesList;
};

class RecipeGridPage extends Component {
    constructor(props) {
        super(props);

        const { recipes, selectedTags } = props;

        if (selectedTags) {
            this.state = {
                filteredRecipes: this.filterRecipes(recipes, selectedTags),
            };
        } else {
            this.state = {
                filteredRecipes: props.recipes,
            };
        }
    }

    onSelectTags = selectedTags => {
        const { recipes, onSelectTags } = this.props;
        this.setState({
            filteredRecipes: this.filterRecipes(recipes, selectedTags),
        });
        onSelectTags(selectedTags);
    };

    filterRecipes = (recipes, selectedTags) => {
        if (!selectedTags.length) {
            return recipes;
        }

        let filteredRecipeList = [];

        selectedTags.forEach(t => {
            filteredRecipeList.push(
                ...Object.keys(recipes).filter(r => recipes[r].tags && recipes[r].tags.indexOf(t) >= 0)
            );
        });

        let filteredRecipes = {};
        new Set(filteredRecipeList).forEach(r => (filteredRecipes[r] = recipes[r]));

        return filteredRecipes;
    };

    render() {
        const { classes, numberPeople, onChangeNumberPeople, tags, selectedTags } = this.props;
        const { filteredRecipes } = this.state;

        return [
            <RecipeAppBar
                key={1}
                numberPeople={numberPeople}
                onChangeNumberPeople={onChangeNumberPeople}
                tags={tags}
                selectedTags={selectedTags}
                onSelectTags={this.onSelectTags}
            />,
            <div key={2} className={classes.root}>
                <Grid container key={2} spacing={1}>
                    {getRecipesList(filteredRecipes).map(recipe => (
                        <Grid item xs={6} sm={4} md={3} lg={2} xl={1} key={recipe.id}>
                            <Link to={'recipes/' + recipe.id} key={recipe.id}>
                                <RecipeTile name={recipe.name} image={recipe.image} author={recipe.author} />
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </div>,
        ];
    }
}

RecipeGridPage.propTypes = {
    numberPeople: PropTypes.number.isRequired,
    onChangeNumberPeople: PropTypes.func.isRequired,
    recipes: PropTypes.object.isRequired,
    tags: PropTypes.object.isRequired,
    selectedTags: PropTypes.array.isRequired,
    onSelectTags: PropTypes.func.isRequired,
};

export default withStyles(styles)(RecipeGridPage);
