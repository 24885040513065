import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';

const styles = theme => ({
    titleBar: {
        background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
        color: 'white',
    },
    paper: {
        overflow: 'hidden',
        display: 'block',
        height: '80%',
    },
    image: {
        position: 'relative',
        width: '100%',
        resizeMode: 'contain',
        height: '100%',
        display: 'inline-block',
        backgroundSize: 'cover',
        // transform: 'translate(-50%)',
        // left: '50%',
    },
    bottom: {
        position: 'absolute',
        display: 'flex',
        bottom: 0,
        left: 0,
        right: 0,
        height: 68,
        background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
});

const RecipeTile = props => {
    const { classes, name, image, author } = props;

    return (
        <GridList cols={1}>
            <GridListTile>
                <img src={'img/' + image} alt={name} />
                <GridListTileBar
                    title={name}
                    subtitle={
                        <span>
                            by <b>{author ? author : 'someone'}</b>
                        </span>
                    }
                    actionIcon={
                        <IconButton className={classes.icon}>
                            <StarBorderIcon />
                        </IconButton>
                    }
                    className={classes.titleBar}
                />
            </GridListTile>
            {/*<Paper className={classes.paper}>
                                <img src={recipe.image} className={classes.image}/>
                                <div className={classes.bottom}>
                                    {recipe.name}
                                    <span>by <b>someone</b></span>
                                    <IconButton className={classes.icon}>
                                        <StarBorderIcon/>
                                    </IconButton>
                                </div>
                            </Paper>
                        */}
        </GridList>
    );
};

RecipeTile.propTypes = {
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    author: PropTypes.string,
};

export default withStyles(styles)(RecipeTile);
